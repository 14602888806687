<template>
  <div
    :style="control.style"
    @click="onClick"
    v-html="control.config.svg"
    style="overflow: hidden"
  ></div>
</template>

<script>
import base64 from "../utils/base64";
import client from "../utils/client";

export default {
  props: { control: Object, isView: Boolean },
  computed: {
    svg() {
      let url = base64.encode(this.control.config.svg);
      return `data:image/svg+xml;base64,${url}`;
    },
  },
  methods: {
    onClick() {
      if (this.isView) {
        for (let i in this.control.config.actions) {
          let action = this.control.config.actions[i];
          if (action.action.type) {
            client.$emit(action.action.type, action.action);
          }
        }
      }
    },
  },
};
</script>